import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./header/header.js";
import Banner from "./banner/banner.js";
import Services from "./services/services.js";
import About from "./about/about.js";
import Footer from "./footer/footer.js";
import ModalContact from './contactform/modal.js';


function App() {
  return (
    <Router>

      <Banner/>
  
      </Router>
  );
}

export default App;
