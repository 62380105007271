import banner from './Banner.png';
import logo from './Logo_remove.png';

function Banner() {
    return (
        <section className="bg-black pb-16">
            <img src={logo} alt="banner" className="mx-auto align-middle mt-40" />
        <div className="container mx-auto text-center mt-20">
          <h2 className="text-white font-bold text-4xl">COMING SOON...</h2>
          
          
          
        </div>
      </section>
    );
  }
  
  export default Banner;