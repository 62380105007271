import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalContact from '../contactform/modal.js';
import logo from './logo_no_bg.png';

function Header() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <header className="bg-zinc-900 py-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <img src={logo} alt="Company Logo" className="h-12 w-12" />
          <h1 className="text-white font-bold text-2xl ml-2">CrystalLabs</h1>
        </div>
        <nav className="flex">
          <a href="#" className="px-4 text-white font-medium">Home</a>
          <a href="#" className="px-4 text-white font-medium">Services</a>
          <a href="#" className="px-4 text-white font-medium">About</a>
          <a href="#" className="px-4 text-white font-medium">Projects</a>
        </nav>
        <button onClick={handleOpenModal} className="bg-zinc-400 hover:bg-zinc-600 text-white font-medium py-2 px-4 rounded">Contact Us</button>
      </div>
      {showModal && <ModalContact showModal={showModal} setShowModal={setShowModal} />}
    </header>
  );
}

export default Header;
